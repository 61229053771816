/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "verwarming/verwarming-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "verwarming/verwarming-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(
      relativePath: { eq: "verwarming/verwarming-7.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `E S I - Centrale verwarming`,
  inner: [
    `Het lijkt zo vanzelfsprekend: een heerlijk warm huis als u thuiskomt en voldoende warm water tijdens het douchen. Hierdoor vergeten we al snel dat hier een hele installatie achter schuilgaat. Uw centrale verwarming bestaat vaak uit meerdere onderdelen: de cv-ketel, radiatoren, convectoren, vloerverwarming en een thermostaat die alles regelt. Werkt een aspect van de cv-installatie niet meer naar behoren, dan kan dit veel discomfort veroorzaken. E S I heeft altijd een pasklare oplossing.`,
  ],
};

const textBottom = {
  header: `Neem contact op`,
  textWithLink: () => (
    <>
      <p>
        Wij zorgen ervoor dat uw centrale verwarming altijd is gericht op de
        toekomst. Neem vrijblijvend{' '}
        <AniLink fade to='/contact' className='text--underline'>
          contact
        </AniLink>{' '}
        met ons op voor een professioneel advies over uw cv-ketel, radiatoren,
        thermostaat of vloerverwarming.
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0642090304'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
      <AniLink fade to='/verwarming' className='ml-md-4x'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const Verwarming = ({ data, path, location }) => {
  const seo = {
    title: `Verwarming`,
    description: `Wij zorgen ervoor dat uw centrale verwarming altijd is gericht op de toekomst. Wij zorgen ervoor dat uw centrale verwarming altijd is gericht op de toekomst.`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Laat u adviseren door onze installateur',
    inner: `Op het gebied van centrale verwarming zijn talloze mogelijkheden. Zo zijn er vele typen cv-ketels, radiatoren, convectoren en thermostaten. De installateur van E S I komt graag langs om de opties door te nemen en u te adviseren. Bij ons bent u zeker van een maatadvies!`,
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Voor al uw verwarmingen`,
    pitchLine: `Wij zorgen er voor dat uw centrale verwarming altijd gericht is op de toekomst.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        textBottom={textBottom}
      />
    </>
  );
};

Verwarming.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Verwarming;
